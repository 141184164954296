<template>
  <div class="page my-page-wrap">
    <div class="inner">
      <div class="content">
        <div class="left">
          <img src="~@/assets/imgs/left.png" alt="" />
        </div>
        <div class="center main-content">
          <div class="custom-tabs">
            <div class="tab" :class="{ active: item.category_id == activeId }" v-for="item in tabs" :key="item.label" @click="tabClick(item)">{{ item.category_name }}</div>
          </div>

          <div class="list">
            <div class="card" v-for="item in list" @click="toDetail(item)" :key="item.article_id">
              <div class="thumb">
                <img :src="$baseRemote + item.cover_pc_img" class="image" />
              </div>

              <div class="right">
                <h2>{{ item.article_title }}</h2>
                <div class="date-num">
                  <i class="el-icon-time"></i>
                  <span style="margin-right: 20px">发布时间: {{dateFormat(item.create_time)}}</span>
                  <i class="el-icon-view"></i>
                  <span style="margin-right: 20px">浏览次数: {{ item.read_num }}次</span>
                </div>

                <div class="subscribe">
                  <p>{{ item.article_abstract }}...</p>
                </div>
              </div>
            </div>
          </div>

          <div class="pagination">
            <el-pagination background layout="prev, pager, next" :total="count" @current-change="handlePageChange" :page-size="pageSize"></el-pagination>
          </div>
        </div>
        <div class="right">
          <img src="~@/assets/imgs/right.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import http from "@/utils/http"

  export default {
    data() {
      return {
        activeId: "",
        tabs: [],
        list: [],
        count: 0,
        currentPage: 1, // 当前页码
        pageSize: 10,
      }
    },

    mounted() {
      this.getCategory()
      // (document.title = "新闻中心-燕台山酒庄官方商城")
    },

    methods: {
      tabClick(item) {
        this.activeId = item.category_id
        this.currentPage = 1;
        this.getDetail(item.category_id)
      },

      getCategory() {
        http({
          url: "/api/articlecategory/page",
          data: {}
        }).then((res) => {
          this.tabs = res.data.list
          this.activeId = 1;
          this.currentPage = 1;
          this.tabClick({ category_id: 1 })
        })
      },
      getDetail(id) {
        http({
          url: "/api/article/page?category_id=" + id,
          data: {
            page: this.currentPage,
            page_size: this.pageSize
          }
        }).then((res) => {
          console.log(res,'++++')
          // this.list = res.data.list
          var jsonArray = res.data.list;
          jsonArray = jsonArray.filter(function(item) {
          return item.article_id !== 13;
          });
          this.list =jsonArray;

          this.count = res.data.count
        })
      },    
      handlePageChange(newPage) {
        this.currentPage = newPage;
        this.getDetail(this.activeId);
      },

      toDetail(item) {
        this.$router.push({ path: "/newsdetail?id=" + item.article_id })
      },
      dateFormat(timestamp) {
        // 创建一个新的Date对象，将时间戳作为参数传入
        var date = new Date(timestamp * 1000);

        // 获取年、月、日、时、分、秒
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var seconds = date.getSeconds();

        // 将月、日、小时、分钟和秒转换为两位数，如果不足两位则补零
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;

        // 组合年、月、日、小时、分钟和秒，以"YYYY-MM-DD HH:MM:SS"的格式输出
        var formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return formattedTime;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page {
    background-color: #f9f9f9;
    .inner {
      padding-top: 0.1rem;
    }
    .card {
      background-color: #fff;
      display: flex;
      margin-bottom: 25px;
      // border-radius: 1px;
      // border: 1px solid #e6e6e6;
      padding: 18px;
      cursor: pointer;

      .thumb {
        border-radius: 8px;
        width: 18%;
        float: left;
        margin-right: 20px;
        max-height: 140px;
        overflow: hidden;
      }
      .image {
        max-width: 100%;
        transition: 0.6s 0.05s;
      }

      .right {
        flex: 1;
        text-align: left;
        h2 {
          font-size: 20px;
          margin-bottom: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .date-num {
        color: #999999;
        font-size: 12px;
        margin-bottom: 10px;
      }
    }
    .card:hover {
      // border: 1px solid #a20000;
      .image {
        transform: scale(1.1);
      }
      h2 {
        color: #a20000;
      }
      .subscribe {
        color: #a20000;
      }
    }

    .pagination {
      margin: 20px 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>

<style lang="scss">
  .page {
    // .btn .el-button {
    //   width: 101px;
    //   height: 28px;

    //   background: #a20000;
    //   border-radius: 6px;
    //   border: none;
    // }

    .pagination {
      .active {
        background: #a20000;
        color: white;
      }
    }

    // 自定义 tabs
    .custom-tabs {
      text-align: center;
      margin-bottom: 30px;
      .tab {
        display: inline-block;
        padding: 0.04rem 0.2rem;
        border-radius: 0.3rem;
        background: #fff;
        margin: 0 0.15rem;
        border: 1px solid #eee;
        font-size: 0.12rem;
        cursor: pointer;
      }
      .tab.active,
      .tab:hover {
        background: #b50003;
        color: #fff;
      }
    }
  }
</style>
